import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import store from "./store";
import history from "./history";
import Layouts from "./layouts";
import { THEME_CONFIG } from "./configs/AppConfig";
import "./lang";
import whiteLabelService from "services/WhiteLabelService";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// if (environment !== 'production') {
// 	mockServer({ environment })
// }

function App() {
  // const [whiteLabel] = useState(
  //   JSON.parse(window.localStorage.getItem("whiteLabel"))
  // );

  useEffect(() => {
    // if (!window.location.host.includes("restory.pro")) {
    //   const post = { domain_name: window.location.host };
    //   try {
    //     const res = axios.post(`${API_BASE_URL}search_by_domain`, post);
    //     localStorage.setItem("whiteLabel", JSON.stringify(res?.data?.data));
    //   } catch (error) {}
    // }
    if (!window.location.host.includes("restory.pro")) {
      const post = { domain_name: window.location.host };
      whiteLabelService
        .getDataFromDomain(post)
        .then((resp) => {
          const whitelabel = resp?.data;
          if(whitelabel)
          {
            localStorage.setItem("whiteLabel", JSON.stringify(whitelabel));
          }
          document.title = whitelabel.brand_name;
          const link = document.createElement("link");
          link.rel = "icon";
          link.href = whitelabel.brand_favicon;
          document.head.appendChild(link);
        })
        .catch(() => {
          document.title = "ReStory";
        });
    } else {
      document.title = "ReStory";
    }
    // if (whiteLabel) {
    //   document.title = whiteLabel.brand_name;
    //   const link = document.createElement("link");
    //   link.rel = "icon";
    //   link.href = whiteLabel.brand_favicon;
    //   document.head.appendChild(link);
    // } else {
    //   document.title = "ReStory";
    // }
  }, []);
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
